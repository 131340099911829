export const pressReleases = [
  {
    date: "January 16, 2023",
    title: "David Joseph Joins XeroThera as President",
    text: [
      `Bryn Mawr, PA—XeroThera, Inc, a privately held biomaterials and med tech company, developing breakthrough technologies for tissue engineering and the controlled and targeted delivery of drugs and biological molecules, today announced that David S. Joseph has joined the company as President. He is also joining its Board of Directors. Paul Ducheyne, Ph.D., the company's founder, will continue as Chairman and Chief Executive Officer.`,
      `Mr. Joseph has over forty years of healthcare and life science start-up and management experience. He has been co-Founder and CEO of five companies in medical technology, biomaterials,  biopharmaceuticals and diagnostics, most recently as co-Founder, President and CEO of AVISA Pharma.  Mr. Joseph also served as Chairman and CEO of Othera Pharmaceuticals and co-Founder of Orthovita Inc, where he served as President and CEO.`,

      `“It is auspicious to be building a company with David again,” said Paul Ducheyne, Ph.D., XeroThera's Founder and CEO, who previously also founded Orthovita. “David's extensive entrepreneurial track record and business development skills will help XeroThera achieve its next level of capitalization and corporate development goals and emerge as a leader in the advanced drug delivery market.”`,

      `“I am very excited to be joining the XeroThera team,” said David Joseph. “I believe XeroThera has a breakthrough technology platform and a great business model that is uniquely positioned to capitalize on tremendous needs in healthcare.  Our first products will revolutionize patient care by preventing orthopedic surgical site infections, a huge burden to the healthcare system.”`,

      `As a founder of Orthovita, David successfully led the company through an IPO, successfully launched products into the dental and orthopedic synthetic graft markets and paved the path leading to the company's eventual acquisition by Stryker in 2011. Prior to Orthovita, Mr. Joseph co-founded two medical device companies: Surgical Laser Technologies, Inc., which became public in 1989 and SITE Microsurgical Systems, which was acquired by Johnson & Johnson Corporation in 1983.  He has served on numerous healthcare company boards over the course of his career. Mr. Joseph received the Greater Philadelphia Venture Group's Raymond Rafferty Entrepreneurial Excellence Award in May 2005 for having founded four successful venture-backed companies and for his influence on the ophthalmic and medical technology industries.`,

      `Mr. Joseph received a MBA in Business and Healthcare Administration from Xavier University.`,

      `XeroThera is a biomaterials and med tech company developing products that offer controlled and targeted delivery of drugs and biological molecules. The technology is the result of twenty years of fundamental studies on sol gel processed, nanoporous, oxide-based materials in academic laboratories at the University of Pennsylvania, Philadelphia.   These products address the prevention and treatment of surgical site infections, including bone infections and resistant bacteria infections, enabling patients to benefit from cost containment and effective post-surgical care.`,
    ],
  },
  {
    date: "June 27, 2024",
    title: "XeroSyn™ Bone Void Filler Receives FDA Market Clearance",
    text: [
      `Bryn Mawr, PA—XeroThera Inc., an emerging biomaterials company, announced today that it has received FDA market clearance for XeroSyn™, its core platform of highly porous and nanostructured biomaterials. XeroSyn is an implant intended to fill bony voids or gaps of the skeletal system. It is used with autograft as a bone graft extender in the extremities and pelvis. XeroSyn resorbs and is replaced with bone during the healing process.`,

      `According to Dr. Paul Ducheyne, Chairman and CEO, “this is a major milestone for the company as we move to the next phase of our regulatory and clinical development. By adding antibiotics to highly porous XeroSyn, a highly controlled release can be achieved for any relevant duration of days, weeks, or months.”`,

      <b>About XeroThera</b>,

      `XeroThera's patented technology, developed at the University of Pennsylvania Center for Bioactive Materials and Tissue Engineering, is in various stages of pre-clinical and clinical development. With its pipeline technology, the company is implementing clinical studies targeting surgical site infections in both hard and soft tissue. XeroThera is a privately held company in the Philadelphia, Pennsylvania area.`,

      <b>For more information contact:</b>,

      [`XeroThera Inc.`,<br/>,<a href="mailto:info@xerotherainc.com">info@xerotherainc.com</a>],
    

    ],
  },
//   {
//     date: "",
//     title: "",
//     text: [
//       ``,
//       ``,
//       ``,
//     ],
//   },

];
