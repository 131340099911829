import './App.css';
import {
  createBrowserRouter,
  Route,
  createRoutesFromElements,
  RouterProvider,
  // useRouteError,
} from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import Home from './components/Home/Home';
import About from './components/About/About';
import Contact from './components/Contact/Contact';
import Team from './components/Team/Team';
import Technology from './components/Technology/Technology';
import Pipeline from './components/Pipeline/Pipeline';
import Investors from './components/Investors/Investors';
import Press from './components/Press/Press';
import PressDetails from './components/Press/PressDetails';
import NotFound from './components/NotFound/NotFound';
import SciencePapers from './components/SciencePapers/SciencePapers';
import SciencePaperDetails from './components/SciencePapers/SciencePaperDetails';
// import Member from './components/Team/Member';

// const Error = () => {
//   const err = useRouteError();
//   console.log("ERROR!!!", err);
//   return (
//     <div className='errorWrapper'>
//       <h2>Error</h2>
//       <p>
//         Oh no, something went wrong! If you believe this was a mistake please
//         email us...
//       </p>
//       <p>
//         Go back to the <a href='/'>homepage.</a>
//       </p>
//     </div>
//   );
// };

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<RootLayout />}>
      <Route path='/' element={<Home />} />
      <Route path='/about/company-overview' element={<About />} />
      <Route path='/about/management' element={<Team />} />
      {/* <Route path='/team/:id' element={<Member />} /> */}
      <Route path='/technology' element={<Technology />} />
      <Route path='/technology/publications' element={<SciencePapers />} />
      <Route path='/technology/publications/:id' element={<SciencePaperDetails />} />
      <Route path='/pipeline' element={<Pipeline />} />
      <Route path='/investors' element={<Investors />} />
      <Route path='/press' element={<Press />} />
      <Route path='/press/:id' element={<PressDetails />} errorElement={<NotFound/>} />
      <Route path='/contact' element={<Contact />} />
      <Route path='*' element={<NotFound />} />
    </Route>
  )
);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
