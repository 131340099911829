import { Link } from "react-router-dom";
import "./Technology.scss";

const Technology = () => {
  return (
    <div className='pageWrapper'>
      <div className='technologyHeader'>
        <div className='textContent'>
          <h1>Technology</h1>
        </div>
      </div>
      <div className="miniNavbar">
        <div className="miniNavLinksWrapper">
          <Link to={'/technology'} className='miniNavLink'>XT Platform</Link>
          <Link to={'/technology/publications'} className='miniNavLink'>Publications</Link>
        </div>
      </div>
      <div className="contentWrapper">
      <div className="technologyPage">
        <h2>XT Technology Platform: Engineered to Combat SSIs</h2>
        <div className="sustainedDeliveryWrapper">
          <div className="sustainedDeliveryText">
            <h3>Controlled, Sustained Delivery</h3>
            <div className="listWrapper">
              <ul>
                <li>Core sol gel processing technology producing xerogels</li>
                <li>Novel nano-structured, highly porous (&lt;80%) biomaterial matrix</li>
                <li>High porosity = high drug loading (pore size 2-3nm)</li>
                <li>Surface treatment contributes to drug encapsulation and prevents burst release</li>
                <li>Provides controlled release of a wide range of drugs</li>
                <li>Tunable release profiles: days, weeks, months, …</li>
                <li>Bioactivity enhances osteoconductive bone growth in bone tissue sites</li>
                <li>Major potential in soft tissue surgeries</li>
              </ul>
            </div>
          </div>
          <div className="deliveryDiagramWrapper">
            <img src='/images/sustainedDelivery.png' alt='Figure 1: Sustained Release of Antibiotic from XeroABX'/>
            <p>Figure 1: Controlled, Sustained Release of Antibiotic from XeroABX</p>
          </div>
        </div>
            <h3 className="pinkSubtitle">Controlled, sustained release of antibiotics (&gt;2 weeks) kills bacteria</h3>
        <div className="bioactivityWrapper">
          <div className="bioactivityText">
            <h3>Bioactivity</h3>
            <div className="listWrapper">
            <p>XeroSyn™ and XeroABX™ are bone bioactive:</p>
              <ul>
                <li>Form carbonated hydroxyapatite layer when immersed in simulated biological fluids.</li>
                <li>Enhance osteoconduction, and osseointegration with surrounding bone.</li>
                <li>Undergo remodeling and are fully replaced by viable bone in bone tissue sites.</li>
                <li><i>In vivo</i> studies show bone healing in critical size defect models.</li>
              </ul>
              </div>
          </div>
          <div className="bioactivityDiagramWrapper">
            <img src='/images/bioactivityXeroGelGlass.png' alt='Figure 2: Bioactive Xerogel Surface Reaction'/>
                <p>Figure 2: Bioactive Xerogel Surface Reaction</p>
          </div>

        </div>
        <div className="moaWrapper">
          <div className="moaText">
            <h3>Mechanisms of Release</h3>
            <p>After XeroABX has absorbed its antibiotic load, there are three mechanisms that act together to release the drug over an extended period of time (Figure 3).</p>
            <div className="moaDiagramWrapper">
              <img src='/images/xeroABXMoaDiagram.jpg' alt='Figure 3: Mechanisms of Realease'/>
              <p>Figure 3: Mechanisms of Release</p>
            </div>
            <ol>
              <li><span>Desorption</span> - Drug molecules detach from the porous granule surfaces.</li>
              <li><span>Resorption</span> - As XeroABX is adsorbed and gradually resorbs in the body, antibiotic molecules trapped inside its pores or adsorbed to its surface are gradually released into the local tissues.</li>
              <li><span>Diffusion</span> - As body fluids diffuse into the pores of XeroABX, antibiotic molecules will diffuse out into the surrounding tissues.</li>
            </ol>
          </div>

        </div>
      </div>
      </div>
    </div>
  );
};

export default Technology;
