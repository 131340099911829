export const sciencePapersList = [
    {
      journal: "J Biomed Mater Res",
      author: "S. Radin, P. Ducheyne, T. Kamplain, B. H. Tan",
      date: "April 10, 2001",
      title: ["Silica sol-gel for the controlled release of antibiotics. I. Synthesis, characterization, and ", <i>in vitro</i>, " release"],
      path: "134-Radin_Ducheyne-sol-gel-vanco-2001.pdf",
      url: "https://drive.google.com/file/d/13Za8CXbglu5CMhVA0nmNXnTl5iNm7e08/preview"
    },
    {
      journal: "Biomaterials",
      author: "S. Radin, P. Ducheyne",
      date: "November 18, 2006",
      title: "Controlled release of vancomycin from thin sol–gel films on titanium alloy fracture plate material",
      path: "159-Thin_films-Biomat_2007.pdf",
      url: "https://drive.google.com/file/d/1VXQ_HJJKT4KkwCnWErKLIICWZn-L-H3D/preview"
    },
    {
      journal: "Acta Biomaterialia",
      author: "S. Bhattacharyya, H. Wang, P. Ducheyne",
      date: "June 1, 2012",
      title: "Polymer-coated mesoporous silica nanoparticles for the controlled release of macromolecules",
      path: "180-Bhattacharyya-MSN-TI-release-Acta-Biomat-2012.pdf",
      url: "https://drive.google.com/file/d/1cERReGJ_0OKehX42PQ33JBQqZN5ubPZ0/preview"
    },
    {
      journal: "Biomaterials",
      author: "S. Bhattacharyya, A. Agrawal, C. Knabe, P. Ducheyne",
      date: "September 23, 2013",
      title: ["Sol–gel silica controlled release thin films for the inhibition of methicillin-resistant ", <i>Staphylococcus aureus</i>],
      path: "184-SG_MRSA-Bhattacharyya_Agrawal_Knabe_Ducheyne-Biomat_2014.pdf",
      url: "https://drive.google.com/file/d/15j_5Wse4wR3GG08MWHXt0z3cX21Ss3Ka/preview"
    },
    {
      journal: "Biomaterials",
      author: "H. Qu, C. Knabe, S. Radin, J. Garino, P. Ducheyne",
      date: "May 24, 2015",
      title: "Percutaneous external fixator pins with bactericidal micron-thin sol–gel films for the prevention of pin tract infection",
      path: "192-QED-Qu_Knabe-Biomat_2015.pdf",
      url: "https://drive.google.com/file/d/145OnjFX2BNVQu17Im5T-n_D0dt7u93ri/preview"
    },
    {
      journal: "Pharm Res",
      author: "H. Qu, M. C. Costache, S. Inan, A. Cowan, D. Devore, P. Ducheyne",
      date: "November 2, 2015",
      title: ["Local, Controlled Delivery of Local Anesthetics ", <i>In Vivo</i>, " from Polymer - Xerogel Composites"],
      path: "194-Qu_et_al-in_vivo_pain-Pharma_Res_2016.pdf",
      url: "https://drive.google.com/file/d/1kLbPxxS8f8GTvtVBKzU0qMunrFuGP4iT/preview"
    },
    {
      journal: "Adv Drug Deliv Rev",
      author: "H. Qu, S. Bhattacharyya, P. Ducheyne",
      date: "May 25, 2015",
      title: "Silicon oxide based materials for controlled release in orthopedic procedures",
      path: "193-Qu_Bhattacharyya_Ducheyne-Silica_contr_release-Adv_Drug_Delivery_Reviews_2015.pdf",
      url: "https://drive.google.com/file/d/1iSjKaIc3-8IhwdW9n3lF2zeISFCbG_LG/preview"
    },
  
  ];
  