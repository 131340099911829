import { Outlet, useLocation } from "react-router-dom";
import Footer from "../components/Footer/Footer";
import ResponsiveAppBar from '../components/Navbar/Navbar';
import "./RootLayout.scss";
import { useEffect } from "react";

const RootLayout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);


  
  
  return (
    <div className='root-layout'>
      <ResponsiveAppBar/>
      <div className="outletWrapper">
        <Outlet />
        <div className="backgroundWrapper">
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default RootLayout;
