import { useState } from "react";
import "./Contact.scss";

const FormSubmitCo = () => {
  const formInitialState = {
    Name: "",
    Email: "",
    Subject: "",
    Message: "",
  };
  const [form, setForm] = useState(formInitialState);
  const [isSubmitted, setIsSubmitted] = useState(false);
  function handleChange(field, str) {
    setForm((prevState) => ({ ...prevState, [`${field}`]: str }));
  }
  function submitForm() {
    fetch("https://formsubmit.co/ajax/info@xerotherainc.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.message === "The form was submitted successfully.") {
          setIsSubmitted(true);
          setForm(formInitialState);
        }
      });
  }
  return (
    <div className='contactFormWrapper'>
      <div className="formHeader">
        <h2>Contact Us</h2>
        <p>Please fill the form below:</p>
      </div>
      {isSubmitted ? (
        <div className='successMessage'>
          <p>Your message has been sent!</p>
        </div>
      ) : (
        <></>
      )}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
          e.target.reset();
        }}
        // target="_blank"
        // method='post'
        // action="https://formsubmit.co/el/fucice"
      >
        <input
          type='hidden'
          name='_subject'
          value='XeroThera Contact Form!'
        ></input>
        <input type='text' name='_honey' style={{ display: "none" }} />
        <input type='hidden' name='_template' value='box' />
        <div className='form-control'>
          <label htmlFor='Name'>Name</label> <br />
          <input
            type='text'
            id='Name'
            name='name'
            placeholder='Full Name'
            onChange={(e) => handleChange(e.target.id, e.target.value)}
            required
          />
        </div>
        <div className='form-control'>
          <label htmlFor='email'>Email</label> <br />
          <input
            type='email'
            id='Email'
            name='email'
            placeholder='Email Address'
            value={form.email}
            onChange={(e) => handleChange(e.target.id, e.target.value)}
            required
          />
        </div>
        <div className='form-control'>
          <label htmlFor='subject'>Subject</label> <br />
          <input
            type='text'
            id='Subject'
            name='subject'
            placeholder='Email Subject'
            value={form.subject}
            onChange={(e) => handleChange(e.target.id, e.target.value)}
            required
          />
        </div>
        <div className='form-control'>
          <label htmlFor='message'>Message</label> <br />
          <textarea
            id='Message'
            name='message'
            placeholder='Type your message here!'
            value={form.message}
            onChange={(e) => handleChange(e.target.id, e.target.value)}
            required
          ></textarea>
        </div>
        <div className='form-control submitBtn'>
          <button
            type='submit'
            disabled={
              form.Name === "" ||
              form.Email === "" ||
              form.Email.includes("@") === false ||
              form.Email.includes(".") === false ||
              form.Subject === "" ||
              form.Message === ""
                ? true
                : ""
            }
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FormSubmitCo;
