import { Link } from "react-router-dom";
import "./SciencePapers.scss";
import { sciencePapersList } from "./SciencePapersList";

const SciencePapers = () => {
  return (
    <div className='pageWrapper'>
      <div className='sciencePaperHeader'>
        <div className='textContent'>
          <h1>Publications</h1>
        </div>
      </div>
      <div className="miniNavbar">
        <div className="miniNavLinksWrapper">
          <Link to={'/technology'} className='miniNavLink'>XT Platform</Link>
          <Link to={'/technology/publications'} className='miniNavLink'>Publications</Link>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='sciencePaperPage'>
          <div className='sciencePaperReleasesWrapper'>
            {sciencePapersList.map((paper, index) => (
              
              // <a key={index} className='articleLink' href={`/images/pdf/${paper.url}`} target="_blank" rel="noreferrer noopener">
              //   <div className='articleWrapper'>
              //     <h2>{paper.title}</h2>
              //     <p>{paper.date}</p>
              //   </div>
              // </a>
              <Link key={index} className='articleLink' to={`/technology/publications/${index}`}>
                <div className='articleWrapper'>
                  <h2>{paper.title}</h2>
                  <p>{paper.author}</p>
                  <p>{paper.journal} - {paper.date}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SciencePapers;
