import './Navbar.scss'
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MuiDrawer from './Drawer';
import { Link as RouterLink }  from 'react-router-dom';

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElNavTech, setAnchorElNavTech] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenNavMenuTech = (event) => {
    setAnchorElNavTech(event.currentTarget);
  };
 
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
    setAnchorElNavTech(null);
  };


  return (
    <nav className='navbarWrapper'>
    <AppBar position="fixed" sx={{bgcolor: 'white', color: 'black'}}>
      <Container maxWidth="lg">
        <Toolbar disableGutters sx={{flexGrow: 1}}>
          {/* desktop company name/logo */}
          <RouterLink to={`/`} className="desktopLogo">
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mx: 2,
                display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex'  },
                fontFamily: 'monospace',
                fontWeight: 700,
                justifyContent: 'center',
                alignItems: 'center',
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img src='/images/xerotheraLogo.svg' width='auto' height='40px' alt='XeroThera Logo'/>
            </Typography>
          </RouterLink>  
          {/* mobile company name/logo */}
          <RouterLink to={`/`} style={{marginRight: 'auto',}} className="mobileLogo">
            <Typography
              variant="h5"
              noWrap
              component="div"
              sx={{
                mr: 2,
                display: { sm: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
    
                alignSelf: 'center',
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              <img src='/images/xerotheraLogo.svg' width='auto' height='40px' className='logoImgMobile' alt='XeroThera Logo'/>
            </Typography>
          </RouterLink>
          {/* DESKTOP LINKS */}
          <Box sx={{ flexGrow: 1, textAlign: 'center', display: { xs: 'none', sm: 'none', md: 'flex', lg: 'flex' } }}>
            <div className='desktopLink'>
              <Button 
                sx={{ color: 'inherit', display: 'block', flexGrow: 1, }}
                aria-controls='about-menu'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={handleOpenNavMenu}
                
              >About</Button>
            </div>
            <Menu id='about-menu'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={anchorElNav !== null}
              disableScrollLock={true}
              onClose={handleCloseNavMenu}
            >
              <RouterLink to={`/about/company-overview`} onClick={handleCloseNavMenu} className='desktopMenuLink'>
                <MenuItem sx={{fontSize: '13px'}}>
                  COMPANY OVERVIEW
                </MenuItem>
              </RouterLink>
              <RouterLink to={`/about/management`} onClick={handleCloseNavMenu} className='desktopMenuLink'>
                <MenuItem sx={{fontSize: '13px'}}>
                  MANAGEMENT
                </MenuItem>
              </RouterLink>
            </Menu>
            <div className='desktopLink'>
              <Button 
                sx={{ color: 'inherit', display: 'block', flexGrow: 1, }}
                aria-controls='technology-menu'
                aria-haspopup='true'
                aria-expanded='false'
                onClick={handleOpenNavMenuTech}
                
              >Technology</Button>
            </div>
            <Menu id='technology-menu'
              anchorEl={anchorElNavTech}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={anchorElNavTech !== null}
              disableScrollLock={true}
              onClose={handleCloseNavMenu}
            >
              <RouterLink to={`/technology`} onClick={handleCloseNavMenu} className='desktopMenuLink'>
                <MenuItem sx={{fontSize: '13px'}}>
                  XT PLATFORM
                </MenuItem>
              </RouterLink>
              <RouterLink to={`/technology/publications`} onClick={handleCloseNavMenu} className='desktopMenuLink'>
                <MenuItem sx={{fontSize: '13px'}}>
                  PUBLICATIONS
                </MenuItem>
              </RouterLink>
            </Menu>
            
            {/* <RouterLink to={`/technology`} onClick={handleCloseNavMenu} className='desktopLink'>
              <Button 
                sx={{ flexGrow:1, color: 'inherit', display: 'block' }}
              >Technology</Button>
            </RouterLink> */}
            <RouterLink to={`/pipeline`} onClick={handleCloseNavMenu} className='desktopLink'>
              <Button 
                sx={{ flexGrow:1, color: 'inherit', display: 'block' }}
              >Pipeline</Button>
            </RouterLink>
            <RouterLink to={`/press`} onClick={handleCloseNavMenu} className='desktopLink'>
              <Button 
                sx={{ flexGrow:1, color: 'inherit', display: 'block' }}
              >Press</Button>
            </RouterLink>
            {/* <Button 
              sx={{ flexGrow:1, mx: 2, my: 2, color: 'inherit', display: 'block' }}
              aria-controls='press-menu'
              aria-haspopup='true'
              aria-expanded='false'
              onClick={handleOpenNavMenu}
              
            >Press</Button>
            <Menu id='press-menu'
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={anchorElNav !== null && anchorElNav.innerText === 'PRESS'}
              onClose={handleCloseNavMenu}
            >
              <MenuItem href='/'>Most Recent</MenuItem>
              <MenuItem>All Releases</MenuItem>
            </Menu> */}

            <RouterLink to={`/investors`} onClick={handleCloseNavMenu} className='desktopLink'>
              <Button 
                sx={{ flexGrow:1, color: 'inherit', display: 'block' }}
              >Investors</Button>
            </RouterLink>
            <RouterLink to={`/contact`} onClick={handleCloseNavMenu} className='desktopLink'>
              <Button 
                sx={{ flexGrow:1, color: 'inherit', display: 'block' }}
              >Contact</Button>
            </RouterLink>
          </Box>
          {/* Mobile Links Drawer */}
          <MuiDrawer/>
        </Toolbar>
      </Container>
    </AppBar>
    <Toolbar/>
    </nav>
  );
}
export default ResponsiveAppBar;
