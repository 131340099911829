import React from "react";
import "./Home.scss";

const Home = () => {
  return (
    <div className='pageWrapper homePage'>
      <div className='homePageHeader'>
        <div className='textContent'>
          <h1>Periprosthetic Joint Infection (PJI):</h1>
          <p className='secondLine'>“… the five year mortality rate for patients with PJI was higher than for breast cancer, melanoma, Hodgkin's lymphoma, and many other cancers.”</p>
          <p className='thirdLine'>Zmistowski B, et al., JBJS 2013.</p>
        </div>
      </div>
      {/* <div className='homePageHeader'>
        <div className='textContent'>
          <h1>Delivering the Future</h1>
          <p className='secondLine'>Nano-structured Xerogel grafts provide bone tissue repair today,</p>
          <p className='thirdLine'>and controlled drug delivery solutions for tomorrow.</p>
        </div>
      </div> */}
      <div className="contentWrapper">

            <div className="xeroGelDescription">
                <h2 className="xeroGelFirstLine">Delivering the Future</h2>
                <h2>XeroABX™</h2>
                <p className="xeroSynSubtitle">Nano-Structured Xerogel Graft</p>
                <p>Provides controlled, sustained release of antibiotics to prevent surgical site infections (SSIs)</p>
                <p>Extensive nano-porosity (&gt;80%) allows high antibiotic loading.</p>
                <p className="xeroSynFDANote">Not for sale in the USA.</p>
            </div>
          </div>
    </div>
  );
};

export default Home;
