import "./Investors.scss";

const Investors = () => {
  return (
    <div className='pageWrapper'>
      <div className='investorsHeader'>
        <div className='textContent'>
          <h1>Investors</h1>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='investorsPage'>
          <h2>Company Highlights</h2>
          <ul>
            <li>Multi-$ Billion markets with unmet needs</li>
            <li>Expansion of indications easily possible</li>
            <li>Extensive proof-of-concept data</li>
            <li>Strong IP</li>
            <li>Platform technology enabling enterprise building</li>
            <li>Potential multiple revenue streams</li>
            <li>Strong science and technology as a basis for strategic partnering</li>
            <li>Attractive to European partners</li>
            <li>Multiple exit options</li>
            <li>Favorable comparables</li>
            <li>Experienced management; serial entrepreneurs</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Investors;
