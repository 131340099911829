import { Link } from "react-router-dom";
import "./Press.scss";
import { pressReleases } from "./pressReleases";

const Press = () => {
  return (
    <div className='pageWrapper'>
      <div className='pressHeader'>
        <div className='textContent'>
          <h1>Press</h1>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='pressPage'>
          <div className='pressReleasesWrapper'>
            {pressReleases.map((release, index) => (
              <Link key={index} className='articleLink' to={`/press/${index}`}>
                <div className='articleWrapper'>
                  <h2>{release.title}</h2>
                  <p>{release.date}</p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Press;
