import { useState, useRef, useEffect } from 'react';
import { Link } from "react-router-dom";
import './Team.scss';

const Team = () => {
    const [selectedMember, setSelectedMember] = useState(null);
    const selectMemberRef = useRef(null);
    const teamMembers = [
        {name: "Paul Ducheyne, PhD", title: "Chairman and CEO", description: "XeroThera is led by a seasoned entrepreneur. Dr. Paul Ducheyne previously founded Orthovita, and was Chairman of the Board of Directors through its IPO. This NASDAQ-listed company was acquired by Stryker in 2011. He has more than 30 years of scientific, technical, entrepreneurial, and governance experience in biomaterials, medical devices, tissue engineering, and controlled release fields, especially as it concerns orthopedics, but also as it relates to cardiology, dentistry, and medicine.", img: '/images/team/paulducheyne.jpg'},
        {name: "David Joseph, MBA", title: "President and Member, Board of Directors", description: "Mr. Joseph has over forty years of healthcare and life science management; co-founder and CEO of five companies in medical technology, biomaterials and biopharmaceuticals, most recently as President and CEO of AVISA Pharma.  Mr. Joseph served as Chairman and CEO of Othera Pharmaceuticals and co-founder  of Orthovita Inc, where he served as President and CEO.  Orthovita was acquired by Stryker in 2012.  Prior to Orthovita, Mr. Joseph co-founded two medical device companies: Surgical Laser Technologies, Inc., which became public in 1989 and SITE Microsurgical Systems, which was acquired by Johnson & Johnson Corporation in 1983. Mr. Joseph received the Greater Philadelphia Venture Group's Raymond Rafferty Entrepreneurial Excellence Award in May 2005 for having founded four successful venture-backed companies and for his influence on the ophthalmic and medical technology industries.", img: '/images/team/davidjoseph.jpg'},
        {name: "Jonathan Garino, MD, MBA", title: "Member, Board of Directors", description: "Jonathan Garino, co-Founder of XeroThera, is a practicing physician, and obtained the M.B.A. degree. He currently is a Clinical Professor of Orthopedic Surgery at Thomas Jefferson Medical College, Philadelphia, is the former director of the Adult Reconstruction Center at the Hospital of the University of Pennsylvania, Philadelphia. He is a national and international expert in hip and knee replacements having participated in numerous scientific studies including 6 IDE trials for ceramic bearings, contributing significantly to bringing this technology to the US market.", img: '/images/team/johnathangarino.jpg'},
        {name: "Sanjib Bhattacharyya, PhD", title: "Vice President, Research & Development", description: "Dr. Bhattacharyya's knowledge of materials chemistry, sol gel science, surface functionalization and biomaterials science is profound and cutting edge. He has a broad background in materials chemistry, biomaterials and pharmacology, with specific training and expertise in nanomaterials for biological applications. He has published well-cited peer-reviewed papers in leading archive science journals. He has presented his groundbreaking work in many international scientific conferences. He is one of the inventors of the technology that forms the basis for XeroThera's first products and will be implementing and managing the upscaling of the controlled release material concepts for production, and develop the internal research program.", img: '/images/team/sanjibbhattacharyya.jpg'},
        {name: "Charles Cohen, PhD", title: "Vice President, Product Development", description: "Charles Cohen is a seasoned biomedical entrepreneur with expertise in applying biomedical technology to commercial opportunity. Previously, Dr. Cohen was the CTO of Polymer Therapeutics which developed novel biomaterial based antimicrobial therapies for prophylactic treatment of surgical site infections.  Dr. Cohen was also a co-founder of Orthovita and directed the Oral, Cranial, and Facial Reconstruction business until the entire product line was sold in a strategic divestiture. Subsequently, Dr Cohen co-founded Gentis and served as its Vice President of Product Development and was a co-inventor for the patents surrounding Gentis' key technology for treating degenerated or herniated discs in the spine.", img: '/images/team/charlescohen.jpg'},
        {name: "Noel Rolon", title: "Vice President, Clinical and Regulatory Affairs", description: "Noel has 30+ years of experience in R&D, RA, Clinical, and Quality. Prior to XeroThera, Noel held leadership positions in Clinical, Regulatory and Quality at various companies including X-Biomedical Inc., Neuronetics Inc, Inovio Pharmaceuticals, Gentis Inc, and Augmenix Inc. where he successfully completed the first industry PMA to De Novo conversion with FDA CDRH. Previously, Mr. Rolon served as Director of Global Clinical Affairs for Synthes Spine and also held various senior regulatory and clinical positions at Johnson & Johnson in their Ethicon, Cordis, and Ortho Clinical Diagnostics divisions. During his time at Johnson & Johnson, Mr. Rolon received the Distinguished Analytical Chemist Award and Ortho Vice President's Award for his work with infectious diseases.", img: '/images/team/noelrolon.jpg'},
    ];
    useEffect(() => {
        function handleClickOff(e){
            if(selectMemberRef.current && !selectMemberRef.current.contains(e.target)){
                setSelectedMember(null);
                document.body.style.overflow = '';
            } 
        };
        function onEscapeKey(e){
            if (e.key === "Escape") {
                setSelectedMember(null);
                document.body.style.overflow = '';
            }
          }
        if(selectedMember !== null){
            document.addEventListener('click', handleClickOff, true);
            document.addEventListener('keydown', onEscapeKey, true);
        };
        return () => {
            document.removeEventListener('click', handleClickOff, true);
            document.removeEventListener('keydown', onEscapeKey, true);
        };
    }, [selectedMember]);
    return (
        <div className='pageWrapper'>
            {!selectedMember ? <></> : 
            <div className='selectedMemberWrapper'>
                <div className='selectedMemberDetails' ref={selectMemberRef}>
                    <button className='closeSelectPersonBtn' onClick={() => {
                        setSelectedMember(null);
                        document.body.style.overflow = '';
                    }}>&times;</button>
                    <div className='selectedMemberContent'>
                        <img src={selectedMember.img} alt={`Team Member: ${selectedMember.name}`}/>
                        <h3 className='selectedMemberName'>{selectedMember.name}</h3>
                        <h4 className='selectedMemberTitle'>{selectedMember.title}</h4>
                        <p className='selectedMemberDescription'>{selectedMember.description}</p>
                    </div>
                </div>
            
            </div>}
            <div className='teamHeader'>
                <div className='textContent'>
                    <h1>Management</h1>
                </div>
            </div>
            <div className="miniNavbar">
                <div className="miniNavLinksWrapper">
                    <Link to={'/about/company-overview'} className='miniNavLink'>Company Overview</Link>
                    <Link to={'/about/management'} className='miniNavLink'>Management</Link>
                </div>
            </div>
            <div className='contentWrapper'>

                <div className='teamPage'>
                    <div className='membersWrapper'>
                        {teamMembers.map((person, index) => (
                            <div key={index} className='personCardWrapper'>
                                <div  className='personDetails' onClick={() => {document.body.style.overflow = 'hidden';
                                return setSelectedMember(person)}}>
                                <img src={person.img} alt={`Team Member: ${person.name}`} className='personImg'/>
                                <div className='personTextWrapper'>
                                <h2 className='personName'>{person.name}</h2>
                                <h3 className='personTitle'>{person.title}</h3>
                                </div>
                                <button className='selectPersonBtn'>+</button>
                                </div>
                            </div>    
                        ))}

            {/* SEPERATE PAGE FOR DETAILS */}
             {/* {teamMembers.map((person, index) => (
                <Link key={index} to={`/team/${person.name.toLowerCase().replace(/\s/g, "-").split(',')[0]}`} state={{ person }} className='memberLink'>
                <div key={index} className='personDetails'>
                    <img src={person.img} alt={`Team Member: ${person.name}`} className='personImg'/>
                    <h3 className='personName'>{person.name}</h3>
                    <h4 className='personTitle'>{person.title}</h4>
                    <button className='selectPersonBtn' onClick={() => (setSelectedMember(person))}>More Info</button>
                    
                </div>
                </Link> 
                
            ))}  */}
            
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Team;
