import React from "react";
import { Link } from "react-router-dom";
import "./About.scss";

const About = () => {
  return (
    <div className='pageWrapper'>
      <div className='aboutHeader'>
        <div className='textContent'>
          <h1>Company Overview</h1>
        </div>
      </div>
      <div className="miniNavbar">
        <div className="miniNavLinksWrapper">
          <Link to={'/about/company-overview'} className='miniNavLink'>Company Overview</Link>
          <Link to={'/about/management'} className='miniNavLink'>Management</Link>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='aboutPage'>
          <h2 className="pinkText">Nano-Structured Materials for Preventing Surgical Site Infections (SSIs)</h2>
          <p>
          XeroThera, Inc. (“XeroThera”) is a clinical stage company leveraging decades of scientific and business leadership in biomaterials to commercialize a patented drug delivery technology to prevent deadly surgical site infections (SSIs) and control surgical pain. As many as 30% of some orthopedic procedures result in a postoperative infection causing untold major extensions of hospital care and unnecessary readmissions.
          </p>
          {/* <ul>
            <li className="pinkText">SSIs cost the US healthcare system up to $10 billion.</li>
          </ul> */}
          <h3 className="pinkText">SSIs cost the US healthcare system up to $10 billion.</h3>
          <p>
          XeroThera's technology platform was developed by leading biomaterials scientists at the Center for Bioactive Materials and Tissue Engineering of the University of Pennsylvania, enabling controlled, sustained release of antibiotics to prevent SSIs.
          </p>
          <ul>
            <li>Based on safe, stable bone graft biomaterials already used in medical device applications (i.e., expedited regulatory pathway).</li>
            <li>Leverages the unique properties of the proprietary surface chemistry, avoids burst release of drug.</li>
            <li>Requires no specialized training or handling, fits within existing physician workflows for surgical procedures.</li>
            <li>Drug delivery profile can be 'tuned' to extend over hours, days, weeks, or months, enabling a range of applications.</li>
          </ul>
          <p className="blueText">XeroThera received FDA clearance in the USA for XeroSyn™ in the second quarter of 2024.</p>
        </div>
      </div>
    </div>
  );
};

export default About;
