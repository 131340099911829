import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Drawer,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import { useState } from "react";
import { Link as RouterLink }  from 'react-router-dom';

const MuiDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  return (
    <>
      <IconButton
        sx={{ display: { sm: "flex", md: "none" } }}
        size='large'
        aria-label='logo'
        edge='start'
        onClick={() => setIsDrawerOpen(true)}
        color='inherit'
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        sx={{ display: { sm: "flex", md: "none" } }}
        anchor='right'
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <Box p={2} width='250px' textAlign='center' role='presentation'>
          <Accordion sx={{boxShadow: "none"}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontSize: "14px" }}>ABOUT</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RouterLink to={`/about/company-overview`} className="mobileMenuLink">
                <Button
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{
                    flexGrow: 1,
                    borderTop: 1,
                    borderRadius: 0,
                    borderColor: 'grey.500',
                    color: "inherit",
                    display: "block",
                  }}
                >
                  Company Overview
                </Button>
              </RouterLink>
              <RouterLink to={`/about/management`} className="mobileMenuLink">
                <Button
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{
                    flexGrow: 1,
                    borderTop: 1,
                    borderRadius: 0,
                    borderColor: 'grey.500',
                    color: "inherit",
                    display: "block",
                  }}
                >
                  Management
                </Button>
              </RouterLink>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{boxShadow: "none", borderTop: 1}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography sx={{ fontSize: "14px" }}>TECHNOLOGY</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <RouterLink to={`/technology/`} className="mobileMenuLink">
                <Button
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{
                    flexGrow: 1,
                    borderTop: 1,
                    borderRadius: 0,
                    borderColor: 'grey.500',
                    color: "inherit",
                    display: "block",
                  }}
                >
                  XT Platform
                </Button>
              </RouterLink>
              <RouterLink to={`/technology/publications`} className="mobileMenuLink">
                <Button
                  onClick={() => setIsDrawerOpen(false)}
                  sx={{
                    flexGrow: 1,
                    borderTop: 1,
                    borderRadius: 0,
                    borderColor: 'grey.500',
                    color: "inherit",
                    display: "block",
                  }}
                >
                  Publications
                </Button>
              </RouterLink>
            </AccordionDetails>
          </Accordion>
          {/* <RouterLink to={`/technology`} className="mobileMenuLink">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                textAlign: "left",
                flexGrow: 1,
                borderTop: 1,
                borderRadius: 0,
                px: 2,
                py: 1,
                color: "inherit",
                display: "block",
              }}
            >
              Technology
            </Button>
          </RouterLink> */}
          <RouterLink to={`/pipeline`} className="mobileMenuLink">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                textAlign: "left",
                flexGrow: 1,
                borderTop: 1,
                borderRadius: 0,
                px: 2,
                py: 1,
                color: "inherit",
                display: "block",
              }}
            >
              Pipeline
            </Button>
          </RouterLink>
          <RouterLink to={`/press`} className="mobileMenuLink">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                textAlign: "left",
                flexGrow: 1,
                borderTop: 1,
                borderRadius: 0,
                px: 2,
                py: 1,
                color: "inherit",
                display: "block",
              }}
            >
              Press
            </Button>
          </RouterLink>
          <RouterLink to={`/investors`} className="mobileMenuLink">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                textAlign: "left",
                flexGrow: 1,
                borderTop: 1,
                borderRadius: 0,
                px: 2,
                py: 1,
                color: "inherit",
                display: "block",
              }}
            >
              Investors
            </Button>
          </RouterLink>
          <RouterLink to={`/contact`} className="mobileMenuLink">
            <Button
              onClick={() => setIsDrawerOpen(false)}
              sx={{
                textAlign: "left",
                flexGrow: 1,
                borderTop: 1,
                borderRadius: 0,
                px: 2,
                py: 1,
                color: "inherit",
                display: "block",
              }}
            >
              Contact
            </Button>
          </RouterLink>
        </Box>
      </Drawer>
    </>
  );
};

export default MuiDrawer;
