import Carousel from "./Carousel";
import "./Pipeline.scss";

const Pipeline = () => {
  return (
    <div className='pageWrapper'>
      <div className='pipelineHeader'>
        <div className='textContent'>
          <h1>Pipeline</h1>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='pipelinePage'>
            <div className="productsSummary">
              <div className="productWrapper">
                <div className="numberLabel">1</div>
                <div className="productText">
                  <h2>XeroSyn™</h2>
                  <p>Nano-Structured Synthetic Bone Graft Microparticles</p>
                </div>
              </div>
              <div className="productWrapper">
                <div className="numberLabel">2</div>
                <div className="productText">
                  <h2>XeroABX™</h2>
                  <p>Microparticles for controlled, sustained delivery of an antibiotic to prevent SSIs</p>
                </div>
              </div>
              <div className="productWrapper">
                <div className="numberLabel">3</div>
                <div className="productText">
                  <h2>XeroDuo™</h2>
                  <p>Injectable nanoparticles for controlled delivery of two antibiotics to kill resistant superbugs</p>
                </div>
              </div>
              <div className="productWrapper">
                <div className="numberLabel">4</div>
                <div className="productText">
                  <h2>XeroMicron™</h2>
                  <p>Thin film implant coating loaded with antibiotic</p>
                </div>
              </div>

            </div>
            <p className="fdaNote firstNote">1: XeroSyn™ is FDA cleared in the USA.</p>
            <p className="fdaNote">2-4: Not for sale in the USA.</p>
            {/* <div className="lumbarWrapper">
              <img src='/images/pipelineLumbar.png' alt='Product Pipeline Diagram'/>
            </div> */}
            <h2>XT Product Pipeline Targets an Enormous Unmet Need and Market Opportunity</h2>
            <p>XeroThera is targeting an enormous patient population and market opportunity. Third party payers no longer have the tolerance for high-cost procedures and hospital stays due to preventable infections. The infection prevention market, when implants are used, is as large as the implant device market itself and creates an enormous opportunity. XeroThera initially focuses on orthopedic surgeries to treat osseous defects which are surgically created or are the result of traumatic injury to the bone. It will expand into elective procedures, specifically hip and knee replacement surgeries (the total number of procedures in the US is estimated to be more than one million cases annually; worldwide, estimates are about double.) Market segment growth will be driven primarily by: (i)  increasing number of days spent in-patient by virtue of unsatisfactory first antibiotic treatment and (ii) steady increases in elderly and immuno-compromised populations.</p>
            <div className="carouselBorder">
              <h2>XeroThera's Lead Products:</h2>
              <p>XeroThera is currently developing XeroSyn™; as well as three additional products referred to as XeroABX™, XeroDuo™, and XeroMicron™ which are in various stages of development.</p>
              <Carousel/>
            </div>
            <h2>2021 Bone Graft Market Size estimated at $2.9 Billion</h2>
            <h2>Proprietary Technology and Science - Intellectual Property Portfolio</h2>
            <p>The science and the team are two pillars as key to success at XeroThera. The third pillar is intellectual property: XeroThera has developed a patent portfolio to build a web of protection surrounding its core sol gel technology. Differentiating factors associated with its fundamental advances include, among other properties:</p>
            <ul>
              <li>Room temperature processing that does not affect the function of the molecules that are incorporated</li>
              <li>Ability to obtain controlled release over three weeks of drugs absorbed during surgical procedures</li>
              <li>Excellent biocompatibility of the oxide based controlled release materials</li>
              <li>Protection of the molecules while in the sol gel</li>
              <li>Delivery of molecules with a short half-life</li>
              <li>Ability to deliver large, fragile bioengineered molecules</li>
              <li>Avoidance of burst release by virtue of processing flexibility</li>
              <li>Ability to deliver molecules with limited bioavailability</li>
              <li>Ability to deliver simultaneously and in a controlled fashion various molecules</li>
            </ul>
        </div>
      </div>
    </div>
  );
};

export default Pipeline;
