import { useState } from "react";
import "./Carousel.scss";
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const Carousel = () => {
  const [currentItem, setCurrentItem] = useState(0);
  const products = [
    {
      name: "XeroSyn™",
      img: "/images/products/XeroSyn.png",
      description: [`XeroSyn™ is a sol-gel synthesized bioactive, osteoconductive synthetic bone graft substitute designed for use as an autograft extender. XeroSyn is composed solely of elements that exist naturally in bone (Ca, P, O, H, Si). The interconnected highly porous structure of XeroSyn readily absorbs body fluids and facilitates quick and easy mixing with autogenous bone. XeroSyn forms a cohesive paste when mixed with saline. XeroSyn is bone-bioactive, which follows from `, <i>in vitro</i>, ` experimentation that shows the formation of a surface apatite layer when the synthetic graft material is immersed in a standard simulated body fluid with the ion concentration of human blood plasma. This apatite layer provides scaffolding onto which the patient's new bone grows, thereby allowing full repair of the defect. XeroSyn is osteoconductive and safe based on `, <i>in vivo</i>, ` studies that show bone healing in a critical size defect model and no effect on distant organs, as confirmed with radiographic, histological, histomorphometric and histopathological analyses. XeroSyn undergoes remodeling and is fully replaced by natural bone as shown in the histology from critical size defect model in the picture above. Its biocompatibility follows from these `, <i>in vivo</i>, ` data, as well as from the full complement of ISO 10993 tests.`],
      fdaNote: "XeroSyn™ is FDA cleared in the USA."
    },
    {
      name: "XeroABX™",
      img: "/images/products/XeroABX.png",
      description: `XeroABX™ is designed to reliably and continuously deliver potent antibiotic directly to the surgical wound at implantation and to slowly release antibiotics throughout the initial postoperative convalescence period. XeroABX is intended for the prevention of surgical infections in orthopedic surgery. General treatment of bone infections (including resistant bone infections) are also anticipated. Eventual commercial use will also include surgical pain treatment. XeroABX is a biocompatible, resorbable microparticle that can be used prophylactically. XeroABX can also be mixed with an antibiotic of choice within fifteen minutes before application during surgery. The antibiotic is then released in a controlled fashion over days and up to three weeks. The product can be designed to be used with an array of antibiotics used in orthopedic surgery, such as gentamicin, vancomycin, cefazolin, rifampin, and others.`,
      fdaNote: 'Not for sale in the USA.'
    },
    {
      name: "XeroDuo™",
      img: "/images/products/XeroDuo.png",
      description: `XeroDuo™ is a unique hybrid inorganic/organic nanoparticle with two mutually complementary potent therapeutic molecules incorporated inside its pores. Critical properties for achieving therapeutic effect, such as constant release of two unique molecules with complementary and synergistic action in the proper ratio for durations of weeks, are what make XeroDuo so attractive. Further, it can be used as an injectable for the treatment of infections developed well after insertion of a permanent device. The indications for XeroDuo are more universal than for XeroABX. It can be used perioperatively, but most importantly it can be injected by virtue of its nanosize, which XeroABX cannot. XeroDuo can be designated as a Qualified Infectious Disease Product (QIDP) for regulatory purposes, and as such, it should be eligible for fast-track regulatory review, priority review for marketing applications, and an additional five years of exclusivity, granted at the time of approval.`,
      fdaNote: 'Not for sale in the USA.'
    },
    {
      name: "XeroMicron™",
      img: "/images/products/XeroMicron.png",
      description: `XeroMicron™ is intended for bacterial inhibition around trauma implants, including percutaneous pins of external fixation devices. Risk of infection is high in the surgical treatment of fractures, and especially in open fractures. Prevention of infection remains challenging, especially when fracture fixation devices are used to stabilize the fractured bones. Nearly two million fracture fixation devices, including internal and external fixation devices, are implanted annually in the US with worldwide numbers being double. They incur an overall postoperative infection rate of 5%. In the specific case when external fixation is used to stabilize the bone fragments, the treatment itself can promote infection, as there is a high risk of infection at the percutaneous passage of the pins into muscle and bone. Deep infection rates of 16% have been reported, with 4% developing chronic osteomyelitis. The infection rate is even greater, up to 32%, with external fixation of femoral fractures.`,
      fdaNote: 'Not for sale in the USA.'
    },
  ];
  function handleShiftLeft() {
    if (currentItem === 0) {
      setCurrentItem(3);
    }
    if (currentItem === 1) {
      setCurrentItem(0);
    }
    if (currentItem === 2) {
      setCurrentItem(1);
    }
    if (currentItem === 3) {
      setCurrentItem(2);
    }
  }
  function handleShiftRight() {
    if (currentItem === 0) {
      setCurrentItem(1);
    }
    if (currentItem === 1) {
      setCurrentItem(2);
    }
    if (currentItem === 2) {
      setCurrentItem(3);
    }
    if (currentItem === 3) {
      setCurrentItem(0);
    }
  }
  return (
    <>
      <div className='carouselWrapper'>
        <button className='leftButton' onClick={() => handleShiftLeft()}><PlayArrowIcon fontSize="2.5rem" sx={{transform: 'scaleX(-1)'}}/></button>
        <div className='carouselItemsWrapper'>
          {products.map((item, index) => (
            <div
              className={
                "carouselItem" +
                (index === currentItem ? " active" : "") +
                (index === currentItem + 1 ? " nextItem" : "") +
                (index === currentItem + 2 ? " hiddenItem" : "") +
                (index === currentItem + 3 ? " prevItem" : "") +
                (index === currentItem - 1 ? " prevItem" : "") +
                (index === currentItem - 2 ? " hiddenItem" : "") +
                (index === currentItem - 3 ? " nextItem" : "")
              }
              key={index}
              onClick={() => (index === currentItem ? (currentItem === 3 ? setCurrentItem(0) : setCurrentItem(index+1)) : setCurrentItem(index))}
            >
              <img src={item.img} alt={item.name}/>
            </div>
          ))}
        </div>
        <button className='rightButton' onClick={() => handleShiftRight()}><PlayArrowIcon fontSize="2.5rem"/></button>
      </div>
      <h3 className="productHeader" key={products[currentItem].name}>{products[currentItem].name}</h3>
      <p className="currentCarouselItemDescription">{products[currentItem].description}</p>
      <p className="currentCarouselItemFDANote">{products[currentItem].fdaNote}</p>
    </>
  );
};

export default Carousel;
