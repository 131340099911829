import { Link, useParams } from 'react-router-dom';
import { pressReleases } from './pressReleases';
import './Press.scss'


const PressDetails = () => {
    let {id} = useParams();
    return (
        <div className='pageWrapper pressPage'>
            <div className='pressDetailsWrapper'>
                <p className='pressDetailsInfo'>FOR IMMEDIATE RELEASE</p>
                <p className='pressDetailsInfo'>{pressReleases[id].date}</p>
                <h1 className='pressDetailsTitle'>{pressReleases[id].title}</h1>
                {pressReleases[id].text.map((paragraph, index) => <p key={index} className='pressDetailsParagraph'>{paragraph}</p>)}
            </div>
            <div className='pressNavLinks'>
            {(parseInt(id)+1) < pressReleases.length ?
                (parseInt(id) !== 0 ? <>
                <Link to={`/press/${(parseInt(id)-1)}`}>Previous Release</Link>
                <Link to={`/press/${(parseInt(id)+1)}`}>Next Release</Link>
                </> : <Link to={`/press/${(parseInt(id)+1)}`}>Next Release</Link>) : (pressReleases.length === 1 ? <></> : <Link to={`/press/${(parseInt(id)-1)}`}>Previous Release</Link>)}
            </div>
        </div>
    );
}

export default PressDetails;
