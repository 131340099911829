import "./NotFound.scss";

const NotFound = () => {
    return (
        <div className="NotFoundWrapper">
            <h1 className="header404">404</h1>
            <p className="message404">Sorry, we can't find the page you are looking for!</p>
        </div>
    );
}

export default NotFound;
