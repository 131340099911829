import { Link, useParams } from 'react-router-dom';
import "./SciencePapers.scss";
import { sciencePapersList } from "./SciencePapersList";

const SciencePaperDetails = () => {
    let {id} = useParams();
    return (
        <div className='pageWrapper sciencePaperPage'>
            <div className='sciencePaperDetailsWrapper'>
                <p className='sciencePaperDetailsInfo'>{sciencePapersList[id].journal} - {sciencePapersList[id].date}</p>
                <h1 className='sciencePaperDetailsTitle'>{sciencePapersList[id].title}</h1>
                <p className='sciencePaperDetailsSubtitle'>{sciencePapersList[id].author}</p>
                <div className='pdfWrapper'>
                    <iframe title={sciencePapersList[id].title} src={sciencePapersList[id].url} height='100%' width='100%'></iframe>
                </div>
            </div>
            <div className='sciencePaperNavLinks'>
            {(parseInt(id)+1) < sciencePapersList.length ?
                (parseInt(id) !== 0 ? <>
                <Link to={`/technology/publications/${(parseInt(id)-1)}`}>Previous Release</Link>
                <Link to={`/technology/publications/${(parseInt(id)+1)}`}>Next Release</Link>
                </> : <Link to={`/technology/publications/${(parseInt(id)+1)}`}>Next Release</Link>) : (sciencePapersList.length === 1 ? <></> : <Link to={`/technology/publications/${(parseInt(id)-1)}`}>Previous Release</Link>)}
            </div>
        </div>
    );
}

export default SciencePaperDetails;
