import { Link } from "react-router-dom";
import "./Footer.scss";

const Footer = () => {
  return (
    <footer>
      <div className='footerIcon'>
        <svg
          width='43.5'
          height='44'
          viewBox='0 0 435 440'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M179.339 119.153C206.024 119.153 227.656 97.521 227.656 70.8364C227.656 44.1519 206.024 22.5198 179.339 22.5198C152.655 22.5198 131.023 44.1519 131.023 70.8364C131.023 97.521 152.655 119.153 179.339 119.153Z'
            fill='white'
          />
          <path
            d='M107.776 307.951C121.118 307.951 131.934 297.135 131.934 283.793C131.934 270.451 121.118 259.635 107.776 259.635C94.4333 259.635 83.6172 270.451 83.6172 283.793C83.6172 297.135 94.4333 307.951 107.776 307.951Z'
            fill='white'
          />
          <path
            d='M277.566 428.353C290.908 428.353 301.724 417.537 301.724 404.195C301.724 390.853 290.908 380.037 277.566 380.037C264.224 380.037 253.408 390.853 253.408 404.195C253.408 417.537 264.224 428.353 277.566 428.353Z'
            fill='white'
          />
          <path
            d='M199.215 163.723C211.222 163.723 220.956 153.989 220.956 141.981C220.956 129.974 211.222 120.24 199.215 120.24C187.207 120.24 177.473 129.974 177.473 141.981C177.473 153.989 187.207 163.723 199.215 163.723Z'
            fill='white'
          />
          <path
            d='M290.118 295.02C304.531 295.02 316.215 283.335 316.215 268.922C316.215 254.509 304.531 242.824 290.118 242.824C275.704 242.824 264.02 254.509 264.02 268.922C264.02 283.335 275.704 295.02 290.118 295.02Z'
            fill='white'
          />
          <path
            d='M334.746 72.7805C346.518 72.7805 356.609 65.9686 361.57 56.126C346.776 42.9213 330.195 31.6851 312.21 22.8364C307.537 28.1427 304.604 35.0325 304.604 42.6581C304.604 59.3029 318.101 72.7805 334.746 72.7805Z'
            fill='white'
          />
          <path
            d='M279.179 178.735C309.418 178.735 333.932 154.221 333.932 123.982C333.932 93.7424 309.418 69.2285 279.179 69.2285C248.939 69.2285 224.425 93.7424 224.425 123.982C224.425 154.221 248.939 178.735 279.179 178.735Z'
            fill='white'
          />
          <path
            d='M430.366 264.761C429.035 271.193 427.403 277.512 425.513 283.73C428.207 277.883 429.893 271.5 430.366 264.761Z'
            fill='white'
          />
          <path
            d='M375.812 206.104C345.568 206.104 321.059 230.619 321.059 260.868C321.059 291.102 345.568 315.626 375.812 315.626C397.875 315.626 416.849 302.533 425.508 283.725C427.398 277.512 429.031 271.193 430.361 264.756C430.453 263.465 430.561 262.183 430.561 260.863C430.565 230.619 406.047 206.104 375.812 206.104Z'
            fill='white'
          />
          <path
            d='M60.5601 137.445C60.5601 119.324 48.4956 104.175 32.0311 99.1316C17.4961 121.156 6.83001 145.947 0.978027 172.557C6.73256 175.734 13.257 177.707 20.2979 177.707C42.5266 177.707 60.5601 159.683 60.5601 137.445Z'
            fill='white'
          />
          <path
            d='M323.052 388.676C345.288 388.676 363.314 370.65 363.314 348.414C363.314 326.177 345.288 308.151 323.052 308.151C300.815 308.151 282.789 326.177 282.789 348.414C282.789 370.65 300.815 388.676 323.052 388.676Z'
            fill='white'
          />
          <path
            d='M258.509 62.9136C278.964 62.9136 295.541 46.3321 295.541 25.8721C295.541 21.974 294.776 18.3001 293.66 14.7723C274.048 7.27829 253.086 2.54213 231.237 0.978027C225.229 7.57065 221.468 16.2536 221.468 25.8721C221.468 46.3321 238.044 62.9136 258.509 62.9136Z'
            fill='white'
          />
          <path
            d='M40.8505 317.229C61.3008 317.229 77.8822 300.647 77.8822 280.187C77.8822 259.742 61.3008 243.146 40.8505 243.146C20.7754 243.146 4.53021 259.143 3.91626 279.062C6.25023 287.438 9.0471 295.614 12.3264 303.556C19.1188 311.845 29.3025 317.229 40.8505 317.229Z'
            fill='white'
          />
          <path
            d='M87.5445 254.621C99.6032 254.621 109.379 244.845 109.379 232.787C109.379 220.728 99.6032 210.953 87.5445 210.953C75.4859 210.953 65.7104 220.728 65.7104 232.787C65.7104 244.845 75.4859 254.621 87.5445 254.621Z'
            fill='white'
          />
          <path
            d='M165.745 407.406C165.745 395.351 155.965 385.577 143.916 385.577C131.851 385.577 122.077 395.356 122.077 407.406C122.077 412.513 123.904 417.151 126.847 420.869C133.644 423.866 140.617 426.541 147.76 428.85C157.968 427.028 165.745 418.15 165.745 407.406Z'
            fill='white'
          />
          <path
            d='M37.4981 234.906C53.1116 234.906 65.7689 222.249 65.7689 206.636C65.7689 191.022 53.1116 178.365 37.4981 178.365C21.8846 178.365 9.22729 191.022 9.22729 206.636C9.22729 222.249 21.8846 234.906 37.4981 234.906Z'
            fill='white'
          />
          <path
            d='M102.294 161.16C122.754 161.16 139.34 144.574 139.34 124.113C139.34 103.653 122.754 87.0671 102.294 87.0671C81.8338 87.0671 65.2476 103.653 65.2476 124.113C65.2476 144.574 81.8338 161.16 102.294 161.16Z'
            fill='white'
          />
          <path
            d='M276.85 325.79C276.694 286.23 244.481 254.27 204.92 254.426C165.345 254.582 133.391 286.78 133.556 326.346C133.703 365.926 165.91 397.87 205.476 397.714C245.046 397.563 277.006 365.365 276.85 325.79Z'
            fill='white'
          />
          <path
            d='M369.059 63.1523C353.116 78.3256 343.156 99.726 343.244 123.48C343.429 169.277 380.694 206.26 426.492 206.08C429.172 206.075 431.832 205.905 434.449 205.651C430.858 149.933 406.529 99.8868 369.059 63.1523Z'
            fill='white'
          />
          <path
            d='M316.137 182.76C304.127 182.809 294.44 192.578 294.484 204.589C294.532 216.585 304.302 226.287 316.313 226.248C328.314 226.194 338.001 216.415 337.957 204.404C337.923 192.403 328.144 182.716 316.137 182.76Z'
            fill='white'
          />
          <path
            d='M89.4156 194.707C96.5281 194.707 102.294 188.942 102.294 181.829C102.294 174.717 96.5281 168.951 89.4156 168.951C82.3031 168.951 76.5374 174.717 76.5374 181.829C76.5374 188.942 82.3031 194.707 89.4156 194.707Z'
            fill='white'
          />
          <path
            d='M155.634 246.986C181.075 246.986 201.7 226.361 201.7 200.92C201.7 175.479 181.075 154.854 155.634 154.854C130.193 154.854 109.569 175.479 109.569 200.92C109.569 226.361 130.193 246.986 155.634 246.986Z'
            fill='white'
          />
          <path
            d='M122.593 30.5352C122.583 27.3875 122.252 24.308 121.638 21.3455C96.0229 33.4587 73.1314 50.3715 54.1234 70.9387C60.8135 74.6711 68.5073 76.8151 76.7079 76.781C102.153 76.6786 122.695 55.9652 122.593 30.5352Z'
            fill='white'
          />
          <path
            d='M129.234 360.166C129.127 334.731 108.424 314.193 82.9887 314.286C61.2325 314.379 43.1065 329.547 38.3557 349.817C54.2014 371.378 73.8964 389.909 96.4224 404.458C115.46 398.738 129.308 381.065 129.234 360.166Z'
            fill='white'
          />
          <path
            d='M243.726 251.308C264.186 251.308 280.772 234.721 280.772 214.261C280.772 193.801 264.186 177.215 243.726 177.215C223.266 177.215 206.68 193.801 206.68 214.261C206.68 234.721 223.266 251.308 243.726 251.308Z'
            fill='white'
          />
          <path
            d='M210.582 404.288C192.013 404.366 176.679 417.824 173.531 435.482C187.087 438.094 201.071 439.512 215.382 439.512C226.491 439.512 237.406 438.669 248.077 437.075C245.568 418.53 229.805 404.219 210.582 404.288Z'
            fill='white'
          />
        </svg>
      </div>

      <div className='footerWrapper'>
        <div className='footerLinksWrapper'>
          <Link to={"/"} className='footerLink'>
            Home
          </Link>
          <Link to={"/about/company-overview"} className='footerLink'>
            Company Overview
          </Link>
          <Link to={"/about/management"} className='footerLink'>
            Management
          </Link>
          <Link to={"/technology"} className='footerLink'>
            XT Platform
          </Link>
          <Link to={"/technology/publications"} className='footerLink'>
            Publications
          </Link>
          <Link to={"/pipeline"} className='footerLink'>
            Pipeline
          </Link>
          <Link to={"/press"} className='footerLink'>
            Press
          </Link>
          <Link to={"/investors"} className='footerLink'>
            Investors
          </Link>
          <Link to={"/contact"} className='footerLink'>
            Contact
          </Link>
        </div>
        <div className='contactInfo'>
          <p className='mailAddress'><span>Corporate Headquarters:</span><br/>XeroThera Inc.<br/>5 W Gay Street<br/>West Chester, PA 19380</p>
          <p className='mailAddress'><span>XeroThera Europe:</span><br/>Av. Hippocrate 5<br/>4000 Liège, Belgium</p>
          {/* <p><span>PHONE:</span> (XXX) XXX-XXXX</p> */}
          <p>
            <span>EMAIL:</span>{" "}
            <a href='mailto:info@xerotherainc.com'>info@xerotherainc.com</a>
          </p>
        </div>
      </div>
      <div className='secondRowWrapper'>
        <a
          href='https://www.linkedin.com/company/xerotherainc/'
          target='_blank'
          rel='noreferrer noopener'
        >
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            fill='white'
            viewBox='0 0 16 16'
          >
            <path d='M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z' />
          </svg>
        </a>
        <div className='copyrightWrapper'>
          <p>©2024 XeroThera, Inc.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
