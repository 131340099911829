import "./Contact.scss";
import FormSubmitCo from "./FormSubmitCo";
import PlaceIcon from "@mui/icons-material/Place";

const Contact = () => {
  return (
    <div className='pageWrapper'>
      <div className='contactHeader'>
        <div className='textContent'>
          <h1>Contact</h1>
        </div>
      </div>
      <div className='contentWrapper'>
        <div className='contactPage'>
          <div className='contactDetails'>
            <div className='hqDetails'>
              <div className='locationName'>
                <h2>Corporate Headquarters</h2>
              </div>
              <div className='locationWrapper'>
                <PlaceIcon sx={{ fill: "#828282" }} />
                <p>XeroThera Inc., 5 W Gay Street, West Chester, PA 19380</p>
              </div>
            </div>
            <div className='europeDetails'>
              <div className='locationName'>
                <h2>XeroThera Europe</h2>
              </div>
              <div className='locationWrapper'>
                <PlaceIcon sx={{ fill: "#828282" }} />
                <p>Av. Hippocrate 5, 4000 Liège, Belgium</p>
              </div>
            </div>
            <img
              src='./images/xerotheraEUBuilding.jpg'
              className='buildingImg'
              alt="XeroThera EU Building"
            />
            <div className='mapWrapper'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m13!1m8!1m3!1d2533.8185773482164!2d5.56815!3d50.574737!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNTDCsDM0JzI3LjciTiA1wrAzNCcwNS4xIkU!5e0!3m2!1sen!2sus!4v1708033521884!5m2!1sen!2sus'
            width='0'
            height='0'
            allowFullScreen=''
            loading='lazy'
            referrerPolicy='no-referrer-when-downgrade'
            title='XeroThera Europe Map'
          ></iframe>
        </div>
          </div>
          <FormSubmitCo />
        </div>
      </div>
    </div>
  );
};

export default Contact;
